/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Image from "gatsby-image"
import {Link} from 'gatsby'

import { rhythm, scale } from "../utils/typography"

const ArticleDetail = ({title, date, image, paragraph, bible_reference, challenge}) => {
    
  return (
    <article>
        <Link to="/">
            ← Ver formato de célula
        </Link>
        <header>
            <h1
                style={{
                marginTop: rhythm(1),
                marginBottom: 0,
                }}
            >
                {title}
            </h1>
            <p
                style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
                }}
            >
                {/* {date} */}
            </p>
        </header>
        
        {bible_reference &&
            <div>
                <section dangerouslySetInnerHTML={{ __html: `<h2>Referencia bíblica</h2>${bible_reference}` }} />
                <hr
                style={{
                    marginBottom: rhythm(1),
                }}
                />
            </div>
        }

        <section dangerouslySetInnerHTML={{ __html: paragraph }} />

        {challenge && challenge.html &&
            <div>
                <hr
                style={{
                    marginBottom: rhythm(1),
                }}
                />
                <section dangerouslySetInnerHTML={{ __html: `<h2>Desafío semanal</h2>${challenge.html}` }} />
            </div>
        }
    
        <hr
        style={{
            marginBottom: rhythm(1),
        }}
        />
        <footer>
            {/* <Bio /> */}
        </footer>
    </article>
  )
}

export default ArticleDetail;
