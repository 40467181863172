import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ArticleDetail from '../components/article-detail'

class PostTemplate extends Component {
  render() {
    console.log(this.props)
    const { data } = this.props.data.post
    const title = data.title.text

    return (
      <Layout location={this.props.location} title={title}>
        <SEO
          title={`Células | ${title}`}
          keywords={[`blog`, `gatsby`, `prismic`]}
        />
        <ArticleDetail
          title={title}
          image={data.image.url}
          paragraph={data.paragraph.html}
          bible_reference={data.bible_reference.html}
          challenge={data.challenge}
        />
      </Layout>
    )
  }
}

export default PostTemplate

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
//   `

export const postQuery = graphql`
  query Post($slug: String) {
    post: prismicArticle(id: { eq: $slug }) {
      id
      data {
        date,
        title {
          text
        }
        image {
          url
        }
        paragraph {
          html
        }
        bible_reference {
          html
        }
        challenge {
          html
        }
      }
    }
  }
`